import './App.css';
import './tablet.css';
import './desktop.css';
import Layout from './Components/Layout';
import Scanner from './Components/Scanner';
import Login from './Components/Login';
import { useState } from 'react';
import NotificationSnackbar from './Components/NotificationSnackbar';
import reloadSvg from './Components/arrow-repeat.svg';

let originalSnackbarConfig = {
  style: {color: '#FFF263', backgroundColor:"#7D2121"},
  msg: "",
  type: "error",
  hideTimeout: 5000,
  messagesDict:{
      "event.loaded": {"de": "Event geladen", "en": "Event loaded"},
      "event.closed": {"de": "Event geschlossen", "en": "Event closed"},
      "event.cannot-close": {"de": "Event nicht geschlossen. Gescannte Tickets müssen synchronisiert werden.", "en": "Event not closed. Scanned tickets must be synchronized."},
      "event.loading": {"de": "Geöffnetes Event wurde neu geladen", "en": "Reloading open event"},
      "event.not-opening": {"de": "Sie müssen das aktuelle Event schließen, bevor Sie ein neues laden können", "en": "You must close the current event before loading a new one"},
      "event.bad-format": {"de": "Ungültiger Code: Bitte scannen Sie einen gültigen Eventcode", "en": "Invalid code: Please scan a valid event code"},
      "event.unauthorized": {"de": "Der Benutzer ist nicht berechtigt, dieses Event zu laden. Bitte laden Sie diese Seite neu und melden Sie sich erneut an", "en": "User is not authorized to load this event. Please reload this page and login again"},
      "event.event-not-found": {"de": "Das angeforderte Event konnte nicht gefunden werden. Bitte scannen Sie einen gültigen Eventcode", "en": "The requested event could not be found. Please scan a valid event code"},
      "event.invalid-date": {"de": "Dieses Event kann frühestens 7 Tage vor Beginn geladen werden", "en": "This event can be loaded at earliest 7 days before the start"},
      "event.failed-fetch-sqi": {"de": "Fehler: Scan-Queue-Items konnten nicht heruntergeladen werden", "en": "Error: failed to download Scan-Queue-Items"},
      "event.failed-fetch-event": {"de": "Das Event konnte nicht geladen werden. Bitte stellen Sie sicher, dass Sie mit dem Internet verbunden sind", "en": "Failed to load this event. Please make sure you are connected to the Internet"},
      "event.failed-get-event": {"de": "Ungültiger Code: Event-Details konnten nicht heruntergeladen werden. Bitte scannen Sie einen gültigen Eventcode", "en": "Invalid code: failed to download event details. Please scan a valid event code"},
      "event.failed-parse-event-details": {"de": "Beim Speichern der Eventdetails ist ein Fehler aufgetreten. Bitte versuchen Sie erneut, dieses Event zu laden", "en": "An error occurred while saving the event details. Please try to load this event again"},
      "event.logout-failed-event": {"de": "Schließen Sie das derzeit aktive Event, bevor Sie sich abmelden", "en": "Close the currently active event before logging out"},
      "ticket.bad-format": {"de": "Ungültiger Code: Bitte scannen Sie einen gültigen Ticketcode", "en": "Invalid Code: Please scan a valid ticket code"},
      "ticket.details-not-found": {"de": "Ungültiger Code: Ticketdetails wurden nicht gefunden. Bitte scanne einen gültigen Ticketcode", "en": "Invalid Code: Ticket details not found. Please scan a valid ticket code"},
      "ticket.event-format": {"de": "Eventcode: Schließe das aktive Event um ein neues Event zu laden", "en": "Event Code: Close currently active event before loading a new one"},
      "ticket.registered": {"de": "Ticket wurde registriert", "en": "Ticket was registered"},
      "login.bad_credentials": {"de": "Ungültige Zugangsdaten", "en": "Incorrect username or password"},
      "login.online_login_error": {"de": "Fehler bei der Anmeldung. Bitte laden Sie diese Seite neu", "en": "An error occurred during login. Please reload this page and try again"},
      "login.empty_credentials": {"de": "Benutzername und Passwort sind erforderlich", "en": "Username and password are required"},
      "login.online_request_error": {"de": "Der Onlinedienst konnte nicht kontaktiert werden. Stellen Sie sicher, dass Sie online sind und laden Sie diese Seite neu", "en": "Online service could not be contacted. Verify that you are online and reload this page"},
      "login.role_not_allowed": {"de": "Keine der Ihrem Konto zugewiesenen Rollen erlaubt die Verwendung dieser App. Bitte versuchen Sie, sich mit einem anderen Konto anzumelden", "en": "None of the roles assigned to your account allow the use of this app. Please try to login with a different account"},
      "login.restored-offline-session": {"de": "Auf dem Gerät gespeicherte Sitzung wurde wiederhergestellt", "en": "Restored session saved on this device"},
      "login.restore-local-failed": {"de": "Gespeicherte Sitzung konnte nicht wiederhergestellt werden. Verbinden Sie sich mit dem Internet und versuchen Sie sich anzumelden", "en": "Failed to restore saved session. Connect to the Internet and try to login"},
      "login.local-session-not-found": {"de": "Der Server konnte nicht kontaktiert werden und auf diesem Gerät wurde keine vorherige Sitzung gefunden. Für die erstmalige Anmeldung müssen Sie online sein", "en": "The server could not be contacted and no previous session was found on this device. You must be online to login for the first time"},
      "troubleshoot.ticket-update-ok": {"de": "Ticket wurde aktualisiert", "en": "Ticket updated"},
      "troubleshoot.settings-not-found": {"de": "Konfiguration nicht gefunden, bitte das Event erneut öffnen", "en": "Settings not found, please re-open event"},
      "troubleshoot.journal-not-found": {"de": "Details zum Historie konnten nicht gefunden werden", "en": "Ticket journal details could not be found"},
      "troubleshoot.ticket-no-details": {"de": "Ticketdetails konnten nicht gefunden werden", "en": "Ticket details could not be found"},
      "troubleshoot.ticket-req-failed": {"de": "Serveranfrage für Ticketdetails hat fehlgeschlagen. Bitte die Seite neu laden und neues Ticket scannen.", "en": "Server request for ticket details failed. Please reload the page and scan new ticket."},
      "troubleshoot.journal-req-failed": {"de": "Serveranfrage für Journaldetails hat fehlgeschlagen. Bitte die Seite neu laden und neues Ticket scannen.", "en": "Server request for journal details failed. Please reload the page and scan new ticket."},
      "troubleshoot.ticket-in-revalidate": {"de": "Fehler: Ticket wurde nicht aktualisiert", "en": "Error: ticket not updated"},
      "troubleshoot.ticket-pdf-req-error": {"de": "Fehler: Server hat nach dem Ticket-PDF Anforderung mit einen Fehler geantwortet", "en": "Error: Server returned an error after requesting ticket PDF"},
      "troubleshoot.ticket-pdf-downloaded": {"de": "Ticket-PDF wurde heruntergeladen", "en": "Ticket PDF downloaded"},
      "troubleshoot.personal-empty-fields": {"de": "Vorname, Nachname und Email sind erforderlich", "en": "First Name, Last Name and Email are mandatory"},
      "troubleshoot.personalized-ticket": {"de": "Personalisiertes Ticket wurde erstellt", "en": "Personalized ticket created"},
      "troubleshoot.personalized-notok": {"de": "Fehler: Anforderungsformat vom Server nicht akzeptiert", "en": "Error: Request format not accepted by server"},
      "troubleshoot.personalized-servererror": {"de": "Ticket konnte nicht personalisiert werden. Der Server hat mit einem Fehler geantwortet", "en": "Could not personalize ticket. Server responded with an error"},
      "troubleshoot.personalized-fetcherror": {"de": "Ticketpersonalisierung konnte nicht angefordert werden. Überprüfen Sie die Netzwerkverbindung", "en": "Failed to request ticket personalization. Verify network connection"},
      "troubleshoot.personalized-emailerror": {"de": "Geben Sie eine gültige E-Mail-Adresse an", "en": "Provide a valid email"}
    }
};


function App() {
  const [token, setToken] = useState('');
  const [userContext, setUserContext] = useState({
      "username": "",
      "password": "",
      "jwt": "",
      "refreshJwt": "",
      "expiration": 0,
      "role": "" //for several roles it would return an array ['scanner', 'troubleshoot?']
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarConf, setSnackbarConf] = useState(originalSnackbarConfig);

  //Creates a Snackbar Toast notification
  //type: 'error' or 'success'
  //msg: is the key of the messages dictionary
  //Language of the displayed message is taken from the currently selected language
  const notification = (type, msg, lang) => {
  	
    let conf = snackbarConf;
    conf.type = type;
    conf.msg = conf.messagesDict[msg][lang];
    conf.style = (type === "error") ? {color: '#FFF263', backgroundColor:"#7D2121"} : {color: '#FFF', backgroundColor:"#42B346"}
    setSnackbarConf(conf);
    setOpenSnackbar(true);
  };

  const handleSnackbarClick = () => {
    setOpenSnackbar(false);
  };

  const snackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    handleSnackbarClick();
  };

  return (
    <Layout>
      <div id="update-modal-id" className="update-modal modal-closed">
        <div className="update-modal-content">
          <div className="update-modal-desc-container">
            <span id="update-modal-close-x" className="close-x">&times;</span>
            <div className="icon-container"><img src={reloadSvg} width="50px" /></div>
            <p id="update-modal-msg-p"></p>
          </div>          
          <div className="update-modal-actions">
            <button id="update-modal-update-btn" value="update">Aktualisieren</button>
            <button id="update-modal-cancel-btn" value="cancel">Abbrechen</button>
          </div>

        </div>
      </div>
      <div className="App">
        { token === true ? 
          <Scanner 
            token={token}
            setToken={setToken}
            userContext={userContext}
            setUserContext={setUserContext}
            notification={notification}
          />
        : <Login setToken={setToken} userContext={userContext} setUserContext={setUserContext} notification={notification} snackbarConf={snackbarConf} snackbarClose={snackbarClose} />
        }
        <NotificationSnackbar snackbarConf={snackbarConf} openSnackbar={openSnackbar} snackbarClose={snackbarClose} />
      </div>
    </Layout>
  );
}

export default App;
