import { Grid } from '@mui/material';
import React, { Component } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';


class ErrorStatus extends Component {
    state = {
        ticket: this.props.ticket,
        errorCodes: this.props.errorCodes,
        lang: (this.props.lang === "de" ? 0 : 1)
    }
    
    render() {
        return (
                <Grid container id="validation-error" className="status error">
                    <Grid item xs={1} md={3}></Grid>
                    <Grid item xs={1} md={1} textAlign="right">
                        <CancelIcon 
                            className="check-icon"
                        />
                    </Grid>
                    <Grid item xs={9} md={4}>
                        <Grid container>
                            <Grid item xs={12}>
                                <span>Ticket {this.state.ticket.code}</span><br />
                                { 
                                    // this.state.ticket.errorCode.map((error) => {
                                    //  return <span key={error}>{this.state.errorCodes[error][this.state.lang]}<br /></span>
                                    // })
                                    this.state.ticket.errorCode.map((error) => {
                                     return <span>{this.state.errorCodes[error][this.state.lang]+", "}</span>
                                    })
                                }
                            </Grid>
                            <Grid item xs={12}>
                             { (this.state.lang === 0 ? "Fehlercode: " : "Error code: ") + this.state.ticket.errorCode.join(", ") }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} md={4}></Grid>
                </Grid>
        );
    }
}

export default ErrorStatus;