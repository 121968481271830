import { Grid } from '@mui/material';
import React, { Component } from 'react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';


class SuccessStatus extends Component {
    state = {
        ticket: this.props.ticket
    }
    
    render() {
        return (
                <Grid container id="validation-success" className="status success">
                    <Grid item xs={1} md={3}></Grid>
                    <Grid item xs={1} md={1} textAlign="right">
                        <CheckRoundedIcon 
                            className="check-icon"
                        />
                    </Grid>
                    <Grid item xs={9} md={4}>
                        <Grid container>
                            <Grid item xs={12} >
                                { this.state.ticket.firstName+" "+this.state.ticket.lastName }
                            </Grid>
                            <Grid item xs={12}>
                                { this.state.ticket.code }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} md={4}></Grid>
                </Grid>
        );
    }
}

export default SuccessStatus;