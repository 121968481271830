import { FormControl, Grid, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { idbHelper, authHelper } from '../Helpers';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from "@mui/material/styles";
import * as CryptoLib from '../Libraries/CryptoLib';

export default function Login({ userContext, setUserContext, setToken, notification, snackbarConf, snackbarClose }) {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [config, setConfig] = useState({language: 'de'});
    const [errorMessage, setErrorMessage] = useState();
    let errMsg;
    let errKey;
    let messagesDict = snackbarConf.messagesDict;
    const dbPromise = idbHelper.openDb();

    const i18nDict = {
        "login.form-title": {"de": "Anmelden", "en": "Sign in"},
        "login.form-logout": {"de": "Ausloggen", "en": "Log out"},
        "login.form-username-input": {"de": "Benutzername", "en": "Username"},
        "login.form-password-input": {"de": "Passwort", "en": "Password"},
        "login.form-submit": {"de": "Senden", "en": "Submit"},
        "login.session-stats-title": {"de": "App Infos", "en": "App info"},
        "login.session-stats-session": {"de": "Benutzersitzung existiert: ", "en": "User session exists: "},
        "login.session-stats-session-confirm": {"de": "ja", "en": "yes"},
        "login.session-stats-session-negative": {"de": "nein", "en": "no"},
        "login.session-stats-event": {"de": "Event geladen: ", "en": "Event loaded: "},
        "login.session-stats-pending-items": {"de": " Elemente noch nicht synchronisiert", "en": " items pending from synchronization"}
    }

    const getText = (key) => {
        return i18nDict[key][config.language];
    }

    const getAppVersion = () => {
        let versionNumberEl = document.querySelector("#app-version");
        if( versionNumberEl != null && versionNumberEl.innerText.length > 0 ){
            return versionNumberEl.innerText;
        }
        else{
            return "vX.X.X";
        }
    }


    const onlineLogin = async (username, password) => {
        //If NO previous session found in Device then try to login using online API:
                //1: Login User and get JWT
                console.log("Attempting Online Login");
                authHelper.loginUser(username, password).then( (response) => {
                    if(response.status === 200){
                        response.json().then(async function(resData) {
                //2: Format authObj
                            let info = authHelper.getTokenInfo(resData.AccessToken);
                            let assignedRole = info.role;
                            let roleIsAllowed = authHelper.isRoleAllowed(assignedRole);
                            
                            if(roleIsAllowed){
                                authHelper.clearUserContext(userContext, setUserContext);
                                userContext.username =   info.name;
                                userContext.hashedPass =   await CryptoLib.hashStr(password);
                                userContext.jwt =        resData.AccessToken;
                                userContext.refreshJwt = resData.RefreshToken;
                                userContext.expiration = info.exp;
                                userContext.role =       info.role; //for several roles it would return an array ['scanner', 'troubleshoot?']
                        //3: Encrypt authObj and 4: Save authObj to indexedDB
                                authHelper.saveToken(userContext).then( () => {
                        //5: Return response
                                    setUserContext(userContext);
                                    setToken(true);
                                });
                            }
                            else{
                                // authHelper.clearUserContext(userContext, setUserContext);
                                errKey = "login.role_not_allowed";
                                errMsg = messagesDict[errKey][config.language];
                                console.log(errMsg, assignedRole);
                                setErrorMessage(errMsg);
                                notification("error", errKey, config.language);
                                setToken(false);    
                            }
                        });
                    }
                    else if(response.status === 401){
                        //The server will respond with a 401 HTTP Status code 
                        //when the user is not allowed to login using the provided credentials
                        errKey = "login.bad_credentials";
                        errMsg = messagesDict[errKey][config.language];
                        console.log("Error during Login - Bad credentials");
                        setErrorMessage(errMsg);
                        notification("error", errKey, config.language);
                    }
                    else{
                        errKey = "login.online_login_error";
                        errMsg = messagesDict[errKey][config.language];
                        console.log("An unidentified error occurred during login");
                        setErrorMessage(errMsg);
                        notification("error", errKey, config.language);
                    }
                }).catch((err) => {
                    console.log("ERROR by online login, service may not be available:",err);
                    authHelper.clearUserContext(userContext, setUserContext);
                    errKey = "login.online_request_error";
                    errMsg = messagesDict[errKey][config.language];
                    setErrorMessage(errMsg);
                    notification("error", errKey, config.language);
                    //In the Troubleshooting-App NO Local Login will executed, since this app MUST be online in order to work
                    //localLogin(username, password);
                });
    }

    const localLogin = (username, password) => {
        console.log("Attempting Local Login");
        //Check if JWT is saved on IndexedDb
        authHelper.jwtExists(dbPromise).then((jwtExists) => {
            if (jwtExists === true){
                //If a previous session already exists in Device then try to restore it:
                //Unencrypt JWT and validate credentials
                authHelper.restoreSession(username, password).then(([isSessionValid, authObj]) => {
                    let assignedRole = authObj.token.role;
                    let roleIsAllowed = authHelper.isRoleAllowed(assignedRole);
                    if(isSessionValid === true){
                        if(roleIsAllowed){
                            authHelper.clearUserContext(userContext, setUserContext);
                            userContext.username =   authObj.username;
                            userContext.hashedPass = authObj.token.hashedPass;
                            userContext.jwt =        authObj.token.jwt;
                            userContext.refreshJwt = authObj.token.refreshJwt;
                            userContext.expiration = authObj.token.expiration;
                            userContext.role =       authObj.token.role; //for several roles it would return an array ['scanner', 'troubleshoot?']
                            setUserContext(userContext);
                            setToken(true);

                            console.log("Success: Session restored: ",authObj);
                            snackbarClose();
                            notification("success", "login.restored-offline-session", config.language);
                        }
                        else{
                            // authHelper.clearUserContext(userContext, setUserContext);
                            setToken(false);

                            errKey = "login.role_not_allowed";
                            errMsg = messagesDict[errKey][config.language];
                            console.log(errMsg, assignedRole);
                            setErrorMessage(errMsg);
                            snackbarClose();
                            notification("error", errKey, config.language);
                        }
                    }
                    else{
                        console.log("Error: Session could not be restored");
                        // authHelper.clearUserContext(userContext, setUserContext);
                        setToken(false);

                        errKey = "login.bad_credentials";
                        errMsg = messagesDict[errKey][config.language];
                        setErrorMessage(errMsg);
                        snackbarClose();
                        notification("error", errKey, config.language);
                    }
                }).catch((err) => {
                    console.log("ERROR by Auth session restore, restoreSession Error:",err);
                    // authHelper.clearUserContext(userContext, setUserContext);
                    //onlineLogin(username, password);
                    notify("error", "login.restore-local-failed");
                });
            }
            else{
                //onlineLogin(username, password);
                notify("error", "login.local-session-not-found");
            }
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        //Check if credentials are compliant with format
        if(userName === '' || password === ''){
            notify("error", "login.empty_credentials");
            return false;
        }
        
        //First will be a local login attempted, this function will fallback to Online login
        //localLogin(userName, password);
        //First will be an Online login attempted, this function will fallback to Local login
        onlineLogin(userName, password);
        //else: Present Login screen --> Login user and save JWT to IndexedDb

    }

    const notify = (type, errKey) => {
        errMsg = messagesDict[errKey][config.language];
        setErrorMessage(errMsg);
        snackbarClose();
        notification(type, errKey, config.language);
    }
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    useEffect( () => {
        // console.log("THE LOGIN COMPONENT HAS BEEN MOUNTED");

        //Verify the current status of authentication info saved on IndexedDB
        //If found any, then make it evident for the user and encourage to restore existing session

        //1) Find if JWT exists
        authHelper.jwtExists(dbPromise).then((jwtExists) => {
            if (jwtExists === true){
        //2) Get username and pre-fill Login input box
                config.jwtExists = true;
                setConfig(config);
                let db = idbHelper.openDb();
                idbHelper.getAuthenticationObj(db).then( (data) => {
                    if(Object.keys(data).length > 0){
                        console.log("Existing Session found in indexedDB");
                        setUserName(data.username);
                    }
                });
            }
        });
        //3) Find if event is currently loaded and inform about stats (Open event details, Scanned Items not yet synchronised )
        idbHelper.getSettings(dbPromise).then((settings) => {
            if(settings && Object.keys(settings).length > 0){
                config.language = (settings.language === "de" || settings.language === "en") ? settings.language : "de";
                config.showStatistics = settings.control_showStatistics;
                config.emergencyMode = settings.control_emergencyMode;
                config.uploaded = !isNaN(settings.uploaded) ? settings.uploaded : 0; 
                config.scanned = !isNaN(settings.scanned) ? settings.scanned : 0; 
                config.scannedInQueue = !isNaN(settings.scannedInQueue) ? settings.scannedInQueue : 0;
                config.isEventLoaded = false;
                config.eventName = "";
                setConfig(config);

                idbHelper.getEvents(dbPromise).then((dbEvent) => {
                    if(dbEvent.length > 0){
                        config.isEventLoaded = true;
                        config.eventName = dbEvent[0].tour.name;
                        setConfig(config);
                    }
                });
            }
        });
    }, []);

    return (
        <div>
                <div className="login-form-wrapper">
                    <div className="login-header-wrapper">
                        <div className="login-header grid-valign-center">
                            <a href='/'><img className='logo-img' src='/logo192.png' /> </a>
                        </div>
                    </div>
                    <div className="login-form-input-wrapper">
                            <div className="login-form">
                                <form onSubmit={handleSubmit}>
                                    <div className="title">{getText("login.form-title")}</div>
                                    <div className="input-container">
                                        <FormControl>
                                            <TextField 
                                                id="login-user-txt" 
                                                variant="filled" 
                                                required={true} 
                                                label={getText("login.form-username-input")}
                                                onChange={ (e) => {setUserName(e.target.value.trim())} }
                                                error={false}
                                                value={userName}
                                                />
                                        </FormControl>
                                    </div>
                                    <div className="input-container">
                                        <FormControl>
                                            <TextField 
                                                className="password-input"
                                                defaultValue="" 
                                                id="login-pass-txt" 
                                                variant="filled" 
                                                type={showPassword ? 'text' : 'password'}
                                                required={true} 
                                                label={getText("login.form-password-input")} 
                                                onChange={ (e) => {setPassword(e.target.value.trim())} }
                                                InputProps={{
                                                    endAdornment:
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() => { setShowPassword(!showPassword) }}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="login-error">{errorMessage}</div>
                                    <div className="button-container">
                                        <input id="form-submit" type="submit" value={getText("login.form-submit")} />
                                    </div>
                                </form>
                            </div>
                    </div>
                </div>
        </div>
    );
}