import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { DialogContentText, List, ListItemText } from '@mui/material';
import { Box } from '@mui/system';
import ListItem from '@mui/material/ListItem';

class EventLoadModal extends Component {
    state = {
        open: true,
    };
    render() {
        const handleClose = () => {
            this.state.open = false;
            this.props.unmountEventDialog();
        };
        const eventLoadedStats = this.props.eventLoadedStats;
        const descs = eventLoadedStats.messagesDict;
        const lang = this.props.language;

        return (
            <div>
                <Dialog 
                    open={this.state.open}
                    onClose={handleClose}
                    maxWidth="sm"
                    fullWidth
                    className="event-load-dialog"
                    key="eventLoadModal"
                    id="event-details-modal"
                >
                    <DialogTitle className="event-load-dialog-title" component="span" variant="h5">{lang === "de" ? "Eventdetails" : "Event details"}</DialogTitle>
                    <Box position="absolute" top={0} right={0}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon className="event-load-dialog-close" />
                        </IconButton>
                    </Box>
                    <DialogContent>
                        <DialogContentText component="div" className="event-load-dialog-content">
                            <List className="event-load-dialog-list">
                                <ListItem><Typography component="span" variant='body1'>{descs["event.scannedStr"][lang] +": "+ eventLoadedStats.scannedStr}</Typography></ListItem>
                                <ListItem><Typography component="span" variant='body1'>{descs["event.eventCode"][lang] +": "+ eventLoadedStats.eventCode}</Typography></ListItem>
                                <ListItem><Typography component="span" variant='body1'>{descs["event.eventId"][lang] +": "+ eventLoadedStats.eventId}</Typography></ListItem>
                                <ListItem><Typography component="span" variant='body1'>{descs["event.name"][lang] +": "+ eventLoadedStats.name}</Typography></ListItem>
                                <ListItem><Typography component="span" variant='body1'>{descs["event.venue_name"][lang] +": "+ eventLoadedStats.venue_name}</Typography></ListItem>
                                <ListItem><Typography component="span" variant='body1'>{descs["event.venue_city"][lang] +": "+ eventLoadedStats.venue_city}</Typography></ListItem>
                                <ListItem><Typography component="span" variant='body1'>{descs["event.starts_at_date"][lang] +": "+ eventLoadedStats.starts_at_date}</Typography></ListItem>
                                <ListItem><Typography component="span" variant='body1'>{descs["event.admission_total_ticket_count"][lang] +": "+ eventLoadedStats.admission_total_ticket_count}</Typography></ListItem>
                                <ListItem><Typography component="span" variant='body1'>{descs["event.admission_outside_count"][lang] +": "+ eventLoadedStats.admission_outside_count}</Typography></ListItem>
                                <ListItem><Typography component="span" variant='body1'>{descs["event.admission_inside_count"][lang] +": "+ eventLoadedStats.admission_inside_count}</Typography></ListItem>
                                <ListItem><Typography component="span" variant='body1'>{descs["event.scanned_items_loaded"][lang] +": "+ eventLoadedStats.scanned_items_loaded}</Typography></ListItem>
                            </List>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="event-load-dialog-actions">
                        <Button 
                            variant='contained'
                            id="close-event-details-btn"
                            onClick={handleClose}
                        >
                            {lang === "de" ? "Schließen" : "Close"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default EventLoadModal;