import { Grid, OutlinedInput, Typography, TextField } from '@mui/material';
import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { HTTPHelper, idbHelper } from "../Helpers"
import TicketJournal from './TicketJournal';

class Troubleshoot extends Component {
    userContext = this.props.userContext;
    setUserContext = this.props.setUserContext;
    token = this.props.token;
    setToken = this.props.setToken;
    notification = this.props.notification;
    lang = this.props.lang;

    state = {
        ticket: {
            b_first_name: "",
            b_last_name:  "",
            b_email: "",
            p_first_name: "",
            p_last_name:  "",
            p_email: "",
            code: this.props.ticket.code,
            status: "",
            category: "",
            price: "",
            seat: "",
            ticket_type: "",
            ticket_journal: [],
            isTicketValid: null,
        },
        oldTicket: {},
        repersonalizedTicket: {
            p_first_name: "",
            p_last_name:  "",
            p_email: "",
            code: null,
        },
        values: {
            firstName: "",
            lastName: "",
            email: ""
        },
        event: this.props.event,
        scannerTemplate: this.props.scannerTemplate,
        //variable used to render the proper section of the workflow,
        //Posible values are: "details", "personalize", "confirmation" 
        stage: "details",
        texts: {
            "troubleshoot.ticket-invalidate-prompt": {"de": "Ticket wird ungültig, zustimmen?", "en": "Ticket will be invalidated, agree?"},
            "troubleshoot.ticket-revalidate-prompt": {"de": "Ticket wird erneut validiert, zustimmen?", "en": "Ticket will be revalidated, agree?"},
            "troubleshoot.ticket-buyer": {"de": "Käufer", "en": "Buyer"},
            "troubleshoot.ticket-personalized": {"de": "Personalisiert", "en": "Personalized"},
            "troubleshoot.ticket-personalized-email": {"de": "Personalisierte Email", "en": "Personalized Email"},
            "troubleshoot.ticket-buyer": {"de": "Käufer", "en": "Buyer"},
            "troubleshoot.ticket-buyer-email": {"de": "Käufer Email", "en": "Buyer Email"},
            "troubleshoot.ticket-category": {"de": "Kategorie", "en": "Category"},
            "troubleshoot.ticket-seat": {"de": "Sitzplatz", "en": "Seat"},
            "troubleshoot.ticket-type": {"de": "Ticket-Typ", "en": "Ticket type"},
            "troubleshoot.ticket-price": {"de": "Ticket-Preis", "en": "Ticket price"},
            "troubleshoot.ticket-journal": {"de": "Historie", "en": "Journal"},
            "troubleshoot.pers-oldticket": {"de": "Altes Ticket", "en": "Old Ticket"},
            "troubleshoot.pers-ticket-newticket": {"de": "Neues Ticket", "en": "New Ticket"},
            "troubleshoot.pers-ticket-firstname": {"de": "Vorname", "en": "First name"},
            "troubleshoot.pers-ticket-lastname": {"de": "Nachname", "en": "Last name"},
            "troubleshoot.pers-personalize": {"de": "Umpersonalisieren", "en": "Personalize"},
            "troubleshoot.pers-create": {"de": "Ticket erstellen", "en": "Create ticket"},
            "troubleshoot.ticket-invalidate": {"de": "Entwerten", "en": "Invalidate"},
            "troubleshoot.ticket-revalidate": {"de": "Gültig machen", "en": "Revalidate"},
            "troubleshoot.ticket-pdf": {"de": "PDF drucken", "en": "PDF Print"},
            "troubleshoot.ticket-cancel": {"de": "Zurück", "en": "Cancel"},
            "troubleshoot.ticket-personalize-prompt": {"de": "Das aktuelle Ticket wird storniert und ein neues Ticket erstellt. Sind Sie damit einverstanden?", "en": "Current ticket will be cancelled and a new ticket will be created. Do you agree to continue?"}
        }
    };

    TICKET_STATES = [
        "",
        "AVAILABLE",
        "RESERVED",
        "CART",
        "SOLD",
        "HOLD",
        "VOID",
        "INVALIDATED",
        "REVALIDATED",
        "BLOCKED"];

    componentDidMount() {
        let dbPromise = idbHelper.openDb();
        let event = this.state.event;
        let that = this;
        
        idbHelper.getSettings(dbPromise).then((settings) => {
            if(settings){
                event.code = settings.eventCode;
                event.id = settings.eventId;
            }
            else{
                console.log("Error by componentDidMount Troubleshoot: no settings found in indexedDB");
                this.notification("error", "troubleshoot.settings-not-found", this.props.lang);
                return false;
            }
        }).then(() => {
            that.fetchTicketDetails(event.code, event.id);
        });
    }

    fetchTicketDetails = (eventCode, eventId, ticketCode) => {
        let that = this;
        let ticket = this.state.ticket;
        if(ticketCode !== undefined){
            this.clearStateTicket();
            ticket.code = ticketCode;   
        }
    
        let httpHelper = HTTPHelper(eventCode, eventId, this.userContext, this.setUserContext, this.setToken);
        if(httpHelper === null){
            return false;
        }
        this.updateSpinner(true);
        httpHelper.getTicketDetails(ticket.code, this.lang).then((response) => {
            if(response.status === 200){
                response.json().then( (data) => {
                    if(data){
                        ticket.status = this.TICKET_STATES[data.article_state_id];
                        ticket.status_name = data.article_state;
                        ticket.b_first_name = data.order_first_name;
                        ticket.b_last_name = data.order_last_name;
                        ticket.b_email = data.order_email;
                        ticket.p_first_name = data.owner_first_name === null ? "" : data.owner_first_name;
                        ticket.p_last_name = data.owner_last_name === null ? "" : data.owner_last_name;
                        ticket.p_email = data.owner_email === null ? "" : data.owner_email;
                        ticket.code = data.code;
                        ticket.category = data.ticket_category_name;
                        ticket.type = data.ticket_type;
                        ticket.price = data.ticket_price_name;
                        ticket.seat = data.seating;

                        ticket.isTicketValid = this.evaluateTicket(data.article_state_id);

                        httpHelper.getTicketJournal(ticket.code, this.lang).then((res) => {
                            if(res.status === 200){
                                res.json().then( (journalData) => {
                                    if(journalData){

                                        let formattedJournalData = this.formatJournalData(journalData);
                                        ticket.ticket_journal = formattedJournalData;
                                        that.setState({ticket: ticket});
                                    }
                                    else{
                                        console.log("No ticket journal data was found");
                                        this.notification("error", "troubleshoot.journal-not-found", this.props.lang);
                                    }
                                });
                            }
                            else{
                                console.log("ERROR: Could not get Ticket Journal info");
                                this.notification("error", "troubleshoot.journal-not-found", this.props.lang);
                            }
                        }).catch((error) => {
                            console.log("ERROR: ticket journal details request failed: ",error);
                            this.notification("error", "troubleshoot.journal-req-failed", this.props.lang);
                            that.updateSpinner(false);
                        }).finally(() => {that.updateSpinner(false)});
                        that.setState({ticket: ticket});
                    }
                    else{
                        console.log("ERROR: details could not be obtained");
                        this.notification("error", "troubleshoot.ticket-no-details", this.props.lang);
                        setTimeout(() => {
                            this.unbindTicket();   
                        }, 2000);
                    }
                })
            }
            else{
                console.log("ERROR: ticket details not found. Scan a valid ticket code");
                this.notification("error", "ticket.details-not-found", this.props.lang);
                setTimeout(() => {
                    this.unbindTicket();   
                }, 3000);
            }
        }).catch((error) => {
            console.log("ERROR: ticket details request failed: ",error);
            this.notification("error", "troubleshoot.ticket-req-failed", this.props.lang);
            setTimeout(() => {
                this.unbindTicket();   
            }, 2000);
        });
    }

    formatJournalData = (journalData) => {
        journalData.map( (item) => { 
            let created = new Date(item.created_at_utc); 
            let comps = item.note.split(" "); 
            item.formatted_created = created.toLocaleDateString(undefined, 
                {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                }
              ) + " "+created.toLocaleTimeString();
        });
        journalData.sort( (a,b) => { return new Date(b.created_at_utc) - new Date(a.created_at_utc) });
        return journalData;
    }

    //Receives the "article_state_id" value from a ticket details and evaluates if that ticket is valid or not
    //If valid, then returns true (for article_state_id = 4 - STATE_SOLD or 8 - STATE_REVALIDATED)
    //If NOT valid, then returns false (for all other article_state_id values)
    evaluateTicket = (article_state_id) => {
        return (article_state_id === 4 || article_state_id === 8) ? true : false;
    }

    clearStateTicket = () => {
        let ticket = this.state.ticket;

        Object.keys(ticket).map((prop)=>{ticket[prop] = ""});
        ticket.ticket_journal = [];
        ticket.isTicketValid = null;
        this.setState({ticket: ticket});   
    }

    //Clear out info of current ticket on stage from the local state and the upper stream (Scanner context)
    unbindTicket = () => {
        this.clearStateTicket();
        this.props.unbindTicket();

    }

    updateStage = (stage) => {
        switch(stage){
            case "personalize":
                let oldTicket = this.state.oldTicket;
                oldTicket = JSON.parse(JSON.stringify(this.state.ticket));
                this.setState({oldTicket: oldTicket});
                break;
        }
        this.setState({stage: stage});
    }

    updateValue = (value, type) => {
        let v = this.state.values; 
        v[type] = value; 
        this.setState({values: v});
    }
    //The navigateCancel function is called when a user clicks on the "cancel" button
    navigateCancel = () => {
        let stage = this.state.stage;
        if(stage === "details"){
            //if current view is ticket details,
            //then clear out current ticket on stage and go back to ticket scan view
            this.unbindTicket();
        }
        else if(stage === "personalize"){
            //if current view is ticket personalize form,
            //then go back to ticket details view
            stage = "details";
            this.setState({stage: stage});
        }
        else if(stage === "confirmation"){
            //if current view is ticket confirmation,
            //then go back to ticket details view with the new ticket
            stage = "details";
            //TODO: fetch new ticket details, before switching to details view
            this.setState({stage: stage});
        } 

    }
    //Creates a Scanner-Item and sends it to the Server for updating the ticket status
    //When the "invalidate" parameter is true, the ticket will be marked as "INVALIDATED"
    //When the "invalidate" parameter is false, the ticket will be marked as "REVALIDATED"
    markTicket = (invalidate) => {
        let event = this.state.event;
        let ticket = this.state.ticket;
        let msg = invalidate ? this.getText("troubleshoot.ticket-invalidate-prompt") : this.getText("troubleshoot.ticket-revalidate-prompt");
        let decision = window.confirm(msg);
        let that = this;

        if(!decision) return false;
        
        let requestsHelper = HTTPHelper(event.code, event.id, this.userContext, this.setUserContext, this.setToken);
        let scannerItem = JSON.parse(JSON.stringify(this.props.scannerTemplate));
        scannerItem.id = event.id;
        scannerItem.data.event_type = "ticket_change";
        scannerItem.data.code = ticket.code;
        scannerItem.data.state = invalidate ? "invalidated" : "revalidated";
        scannerItem.data.admission_count = invalidate ? 0 : (ticket.admission_count > 0 ? ticket.admission_count : 1);
        scannerItem.data.lat = null;
        scannerItem.data.lng = null;
        scannerItem.data.acc = "";
        scannerItem.data.uid = "";
        scannerItem.data.changed_at = new Date().toISOString();
        let scannerItemStr = "id="+event.id+"&data=["+JSON.stringify(scannerItem)+"]";

        this.updateSpinner(true);
        requestsHelper.sendScannerItems(scannerItemStr).then((res) => {
            if(res.status === 200){
                this.notification("success", "troubleshoot.ticket-update-ok", this.props.lang);
                setTimeout(() => {
                    that.updateStage("details");
                    that.fetchTicketDetails(event.code, event.id);
                }, 1500)
            }
            else{
                console.log("Server returned an error while sending Scanner Items to server");
                this.notification("error", "troubleshoot.ticket-in-revalidate", this.props.lang);
                throw new Error("Server returned an error while sending Scanner Items to server");
            }
        }).finally(() => {this.updateSpinner(false)});
    }

    printPDF = () => {
        let ticket = this.state.ticket;
        let event = this.state.event;
        let requestsHelper = HTTPHelper(event.code, event.id, this.userContext, this.setUserContext, this.setToken);

        this.updateSpinner(true);
        requestsHelper.getTicketPDF(ticket.code)
        .then((res) => {
            if(res.status === 200){
                return res.blob();
            }
            else{
                console.log("Server returned an error while ticket PDF");
                this.notification("error", "troubleshoot.ticket-pdf-req-error", this.props.lang);
                throw new Error("Error: Server returned an error while getting ticket PDF");
            }
        })
        .then((blob) => {
            if(!blob) return false;

            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = ticket.code+".pdf";
            document.body.appendChild(a);
            a.click();
            this.notification("success", "troubleshoot.ticket-pdf-downloaded", this.props.lang);
            setTimeout( () => {
                a.remove();  
            }, 100);
        }).catch((error) => {
            //Implement catch logic
        })
        .finally(() => {this.updateSpinner(false)});
    }

    getText = (key) => {
        return this.state.texts[key][this.props.lang];
    }

    canPersonalize = () => {
        if(this.state.ticket.status === "SOLD" || this.state.ticket.status === "REVALIDATED"){
            return true;
        }
        else{
            return false;
        }
    }

    canChangeStatus = () => {
        if(this.state.ticket.status === "SOLD" || this.state.ticket.status === "REVALIDATED" || this.state.ticket.status === "INVALIDATED"){
            return true;
        }
        else{
            return false;
        }
    }

    validateTicketForm = () => {
        let values = this.state.values;
        if(values.firstName.trim() === "" || values.lastName.trim() === "" || values.email.trim() === "" ){
            this.notification("error", "troubleshoot.personal-empty-fields", this.props.lang);
            return false;
        }

        let isValidEmail = values.email.trim().toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
        if(!isValidEmail){
            this.notification("error", "troubleshoot.personalized-emailerror", this.props.lang);
            return false;
        }
        
        return true;
    }

    personalizeTicket = () => {
        let validation = this.validateTicketForm();
        let event = this.state.event;
        let values = this.state.values;
        let ticket = this.state.ticket;
        let personalized = this.state.repersonalizedTicket;
        let that = this;
        
        let msg = this.getText("troubleshoot.ticket-personalize-prompt");
        
        if(validation){
            let proceed = window.confirm(msg);
            if(proceed){
                //Proceed to update ticket in server
                let httpHelper = HTTPHelper(event.code, event.id, this.userContext, this.setUserContext, this.setToken);
                if(httpHelper === null){
                    console.log("Error by instantiation of HTTPHelper. Please refresh page.");
                    return false;
                }
                this.updateSpinner(true);
                httpHelper.personalizeTicket(values, ticket.code).then((response) => {
                    if(response.status === 200){
                        response.json().then( (data) => {
                            if(data.status === "ok"){
                                let newTicket = data.new_ticket.article_ticket;
                                personalized.p_first_name = newTicket.owner_first_name;
                                personalized.p_last_name = newTicket.owner_last_name;
                                personalized.p_email = newTicket.owner_email;
                                personalized.code = newTicket.code;
                                that.setState({repersonalizedTicket: personalized});
                                that.fetchTicketDetails(event.code, event.id, personalized.code);
                                that.notification("success","troubleshoot.personalized-ticket", that.props.lang);
                                this.updateStage("confirmation");
                            }
                            else{
                                that.notification("error","troubleshoot.personalized-notok", that.props.lang);
                                console.log("Error: Request format not accepted by server.");
                            }
                        });
                    }
                    else{
                        that.notification("error","troubleshoot.personalized-servererror", that.props.lang);
                        console.log("Error: could not personalize ticket. Server responded with an error.");
                    }
                }).catch((error) => {
                    console.log("Error: could not request ticket personalization.",error);
                    that.notification("error","troubleshoot.personalized-fetcherror", that.props.lang);
                    throw new Error("Error: could not request ticket personalization.");
                }).finally(() => {this.updateSpinner(false)});
            }
        }
        else{
            //skip ticket update
            console.log("The personalize form is not valid. Skipped Ticket personalization.");
        }
    }
    
    updateSpinner = (newState) => {
        this.props.toogleSpinner(newState);
    }

    render() {
        return (
            <div id="ticket-details" className="ticket-details">
                { this.state.stage === "details" ? 
                    <div id="details-container" className="ticket-details-grid">
                        <div className="desc-wrapper">
                            <div className="desc-title">{this.getText("troubleshoot.ticket-personalized")}</div>
                            <div className="desc-value">{this.state.ticket.p_first_name+" "+this.state.ticket.p_last_name}</div>
                        </div>
                        <div className="desc-wrapper">
                            <div className="desc-title">{this.getText("troubleshoot.ticket-personalized-email")}</div>
                            <div className="desc-value">{this.state.ticket.p_email}</div>
                        </div>
                        <div className="desc-wrapper">
                            <div className="desc-title">{this.getText("troubleshoot.ticket-buyer")}</div>
                            <div className="desc-value">{this.state.ticket.b_first_name+" "+this.state.ticket.b_last_name}</div>
                        </div>
                        <div className="desc-wrapper">
                            <div className="desc-title">{this.getText("troubleshoot.ticket-buyer-email")}</div>
                            <div className="desc-value">{this.state.ticket.b_email}</div>
                        </div>
                        <div className="desc-wrapper">
                            <div className="desc-title">Code</div>
                            <div id="details-code" className="desc-value">{this.state.ticket.code}</div>
                        </div>
                        <div className="desc-wrapper">
                            <div className="desc-title">Status</div>
                            <div id="details-status" className="desc-value">{this.state.ticket.status_name}</div>
                        </div>
                        <div className="desc-wrapper">
                            <div className="desc-title">{this.getText("troubleshoot.ticket-type")}</div>
                            <div id="details-code" className="desc-value">{this.state.ticket.type}</div>
                        </div>
                        <div className="desc-wrapper">
                            <div className="desc-title">{this.getText("troubleshoot.ticket-category")}</div>
                            <div id="details-code" className="desc-value">{this.state.ticket.category}</div>
                        </div>
                        <div className="desc-wrapper">
                            <div className="desc-title">{this.getText("troubleshoot.ticket-price")}</div>
                            <div id="details-code" className="desc-value">{this.state.ticket.price}</div>
                        </div>                        
                        <div className="desc-wrapper">
                            <div className="desc-title">{this.getText("troubleshoot.ticket-seat")}</div>
                            <div id="details-code" className="desc-value">{this.state.ticket.seat}</div>
                        </div>                        
                        <div className="desc-wrapper">
                            <div className="desc-title ticket-journal-title">{this.getText("troubleshoot.ticket-journal")}: </div>
                            <div id="details-journal" className="desc-value ticket-journal-container">
                                <TicketJournal journalItems={this.state.ticket.ticket_journal} />
                            </div>
                        </div>
                    </div>
                :
                    <div className="ticket-details-grid">
                        <div className="sec-title"><span>{this.getText("troubleshoot.pers-oldticket")}</span></div>
                        <div className="desc-wrapper">
                            <div className="desc-title">Code</div>
                            <div className="desc-value">{this.state.oldTicket.code}</div>
                        </div>
                        <div className="desc-wrapper">
                            <div className="desc-title">{this.getText("troubleshoot.ticket-personalized")}</div>
                            <div className="desc-value">{this.state.oldTicket.p_first_name+" "+this.state.oldTicket.p_last_name}</div>
                        </div>
                        <div className="desc-wrapper">
                            <div className="desc-title">{this.getText("troubleshoot.ticket-personalized-email")}</div>
                            <div className="desc-value">{this.state.oldTicket.p_email}</div>
                        </div>
                        <div className="desc-wrapper">
                            <div className="desc-title">{this.getText("troubleshoot.ticket-buyer")}</div>
                            <div className="desc-value">{this.state.oldTicket.b_first_name+" "+this.state.oldTicket.b_last_name}</div>
                        </div>
                        <div className="desc-wrapper">
                            <div className="desc-title">{this.getText("troubleshoot.ticket-buyer-email")}</div>
                            <div className="desc-value">{this.state.oldTicket.b_email}</div>
                        </div>

                        <div className="sec-title">
                            <span>{this.getText("troubleshoot.pers-ticket-newticket")}</span>
                        </div>
                        
                        { this.state.stage === "personalize" ?
                        <div>
                            <div className="desc-wrapper">
                                <div className="desc-title">{this.getText("troubleshoot.pers-ticket-firstname")}</div>
                                <div className="desc-value desc-editor">
                                    <TextField
                                    hiddenLabel
                                        variant="outlined"
                                        fullWidth 
                                        type='text'
                                        size="small"
                                        className="txt-box"
                                        value={this.state.values.firstName}
                                        onChange={(e) => { this.updateValue(e.target.value, "firstName"); }}
                                    />
                                </div>
                            </div>

                            <div className="desc-wrapper">
                                <div className="desc-title">{this.getText("troubleshoot.pers-ticket-lastname")}</div>
                                <div className="desc-value desc-editor">
                                    <TextField
                                    hiddenLabel
                                        variant="outlined"
                                        fullWidth 
                                        type='text'
                                        size="small"
                                        className="txt-box"
                                        value={this.state.values.lastName}
                                        onChange={(e) => { this.updateValue(e.target.value, "lastName"); }}
                                    />
                                </div>
                            </div>
                            
                            <div className="desc-wrapper">
                                <div className="desc-title">Email</div>
                                <div className="desc-value desc-editor">
                                    <TextField
                                    hiddenLabel
                                    variant="outlined"
                                        fullWidth 
                                        type='text'
                                        size="small"
                                        className="txt-box"
                                        value={this.state.values.email}
                                        onChange={(e) => { this.updateValue(e.target.value, "email"); }}
                                    />
                                </div>
                            </div>
                        </div> 
                        : 
                        //Section for showing results of Personalization function
                        <div className="ticket-details-grid">
                            <div className="desc-wrapper">
                                <div className="desc-title">Code</div>
                                <div className="desc-value">{this.state.repersonalizedTicket.code}</div>
                            </div>

                            <div className="desc-wrapper">
                                <div className="desc-title">{this.getText("troubleshoot.ticket-personalized")}</div>
                                <div className="desc-value">{this.state.repersonalizedTicket.p_first_name+" "+this.state.repersonalizedTicket.p_last_name}</div>
                            </div>

                            <div className="desc-wrapper">
                                <div className="desc-title">Email</div>
                                <div className="desc-value">{this.state.repersonalizedTicket.p_email}</div>
                            </div>
                        </div> 
                        }
                    </div>
                }
                <div id="ticket-actions" className="ticket-actions">
                    <Grid className="ticket-actions-container">
                        <Grid container direction="row" rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="ticket-actions-row">
                                {
                                    ( this.state.stage === "details") ?
                                        <Grid item xs={6} className="action-btn-container">
                                            <Button id="personalize-btn" variant="contained" disabled={!this.canPersonalize()} onClick={ () => {this.updateStage("personalize")} }>{this.getText("troubleshoot.pers-personalize")}</Button>
                                        </Grid>
                                    :
                                    ( (this.state.stage === "personalize") ?
                                        <Grid item xs={6} id="personalize-save-btn-container" className="action-btn-container">
                                            <Button id="personalize-save-btn" variant="contained" disabled={!this.canPersonalize()} onClick={ () => { this.personalizeTicket() } }>{this.getText("troubleshoot.pers-create")}</Button>
                                        </Grid>
                                        : ""
                                    )
                                }

                        { (this.state.stage === "details") ?

                            <Grid item xs={6} className="action-btn-container">
                                { this.state.ticket.isTicketValid ?
                                    <Button id="invalidate-btn" variant="contained" disabled={!this.canChangeStatus()} onClick={ () => {this.markTicket(true)} }>{this.getText("troubleshoot.ticket-invalidate")}</Button>
                                    :
                                    <Button id="validate-btn" variant="contained" disabled={!this.canChangeStatus()} onClick={ () => {this.markTicket(false)} }>{this.getText("troubleshoot.ticket-revalidate")}</Button>
                                }
                            </Grid> 

                        : ""
                            }

                        { (this.state.stage === "details" || this.state.stage === "confirmation") ?

                            <Grid item xs={6} className="action-btn-container">
                                <Button id="print-btn" variant="contained" onClick={this.printPDF}>{this.getText("troubleshoot.ticket-pdf")}</Button>
                            </Grid>

                        : ""
                        }
                            <Grid item xs={6} className="action-btn-container">
                                <Button id="cancel-btn" variant="contained" onClick={this.navigateCancel}>{this.getText("troubleshoot.ticket-cancel")}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default Troubleshoot;