import React, { Component } from 'react';
import { Grid } from '@mui/material';

class TicketJournal extends Component {
    render() {
        let journalItems = this.props.journalItems;
        
        return (
            <div>
                <Grid container>
                        {
                            journalItems.map((item) => {
                                return <Grid item xs={12} key={item.id}><span>{ item.formatted_created + " " + item.note}</span></Grid>
                            })
                        }
                </Grid>
  
            </div>
        );
    }
}

export default TicketJournal;